.tippy-box[data-theme~="tooltip"] {
  color: var(--tooltip-color);
  padding: 0.45rem 0.8rem;
  border-radius: 0.66rem;
  box-shadow: var(--tooltip-box-shadow);
  font-size: 0.75rem;
  line-height: 1.6;
  max-width: min(50vw, 1000px) !important;
  white-space: pre-line;
  z-index: 0;
  background: color-mix(in sRGB, var(--background) 85%, transparent);
  backdrop-filter: var(--tooltip-backdrop-filter);

  .tippy-content {
    padding: 0;
  }

  // We style tables as they have additional margin/border radius when in tooltips
  .jenkins-tooltip--table-wrapper {
    background-color: rgba(black, 0.05);
    margin: -0.45rem -0.8rem;
    border-radius: 0.6rem;
  }

  .jenkins-table {
    --table-background: transparent;
    --table-border-radius: 8px;

    margin: 0;
    width: 450px;
  }

  .jenkins-keyboard-shortcut {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 0.375rem;
      border: var(--jenkins-border-width) solid var(--text-color-secondary);
      opacity: 0.3;
      mask-image: linear-gradient(
        -45deg,
        transparent 40%,
        white,
        transparent 60%
      );
      mask-size: 200% 200%;
      animation: shortcut-glow-animation 1.25s forwards linear;
    }

    @keyframes shortcut-glow-animation {
      0% {
        opacity: 0;
        mask-position: 100% 100%;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        mask-position: 0;
      }
    }
  }
}

.tippy-box[data-animation="tooltip"][data-state="hidden"] {
  opacity: 0;
  transform: scale(0.995);

  &[data-placement^="top"] {
    transform-origin: bottom;
    transform: translateY(2px) scale(0.995);
  }

  &[data-placement^="bottom"] {
    transform-origin: top;
    transform: translateY(-2px) scale(0.995);
  }
}

// Workaround for NG Warnings which supports modern Tippy tooltips and a custom solution,
// hide the custom solution
.jenkins-table .healthReportDetails {
  display: none !important;
}

.jenkins-keyboard-shortcut {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.7em;
  min-height: 1.7em;
  padding-inline: 0.55ch;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0.375rem;
    border: var(--jenkins-border-width) solid var(--text-color-secondary);
    opacity: 0.2;
  }

  svg {
    width: 1em;
    height: 1em;
  }
}

.jenkins-keyboard-shortcut__tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.6ch;
}
